import React, { useState, useEffect, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from '../axiosConfig';
import { Box, Button, Container, Typography } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

function EnumerateEndpoints({ selectedFolder }) {
  const [endpoints, setEndpoints] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    if (selectedFolder) {
      const fetchEndpoints = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${selectedFolder}/endpoints/urls.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, endpoint: line }));
          setEndpoints(data);
        } catch (error) {
          console.error(error);
          setEndpoints([]);
        }
      };
      fetchEndpoints();
    }
  }, [selectedFolder]);

  const columnDefs = [
    {
      headerName: 'Endpoint',
      field: 'endpoint',
      filter: 'agTextColumnFilter',
      sortable: true,
      flex: 1,
    }
  ];

  const defaultColDef = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Enumerate Endpoints
      </Typography>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={onExportClick}>
          Export to CSV
        </Button>
      </Box>
      <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={endpoints}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
        />
      </div>
      <ToastContainer position="bottom-right" />
    </Container>
  );
}

export default EnumerateEndpoints;
