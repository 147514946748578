import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from '../axiosConfig';
import { Box, Button, TextField, Container, Typography, CircularProgress } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './custom-ag-theme-dark.css';
import { useThemeContext } from '../ThemeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubdomainForm = ({ selectedFolder }) => {
  const { mode } = useThemeContext();
  const [subdomain, setSubdomain] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subdomains, setSubdomains] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    if (selectedFolder) {
      const fetchSubdomains = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${selectedFolder}/subdomains/subdomains.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
          setSubdomains(data);
        } catch (error) {
          console.error(error);
          setSubdomains([]);
        }
      };
      fetchSubdomains();
    }
  }, [selectedFolder]);

  const columnDefs = [
    { headerName: 'Subdomain', field: 'subdomain', flex: 1 },
  ];

  const defaultColDef = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    flex: 1
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  useEffect(() => {
    const savedColumnState = JSON.parse(localStorage.getItem('subdomainColumnState'));
    if (savedColumnState && gridRef.current) {
      gridRef.current.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  }, []);

  const onColumnMoved = useCallback(() => {
    const columnState = gridRef.current.columnApi.getAllColumns().map(col => ({
      colId: col.colId,
      hide: col.visible === false
    }));
    localStorage.setItem('subdomainColumnState', JSON.stringify(columnState));
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    const savedColumnState = JSON.parse(localStorage.getItem('subdomainColumnState'));
    if (savedColumnState) {
      params.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  };

  const handleSubmitSubdomain = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info('Enumeration started');
    const formData = new FormData();
    formData.append('domain', subdomain);
    formData.append('folder', selectedFolder);

    try {
      const response = await axios.post('/subdomains/enumerate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubdomains(response?.data?.subdomains || []); // Update the subdomains state
      toast.success(response?.data?.message || 'Enumeration completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info('Enumeration started');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder_name', selectedFolder);

    try {
      const response = await axios.post('/subdomains/enumerate-list', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubdomains(response?.data?.subdomains || []);
      toast.success(response?.data?.message || 'Enumeration completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Enumerate Subdomains
      </Typography>
      <Box component="form" onSubmit={handleSubmitSubdomain} sx={{ mt: 2 }}>
        <TextField
          label="Subdomain"
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Submit Subdomain'}
        </Button>
      </Box>
      <Box component="form" onSubmit={handleSubmitFile} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Choose File
          <input
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        {file && (
          <Typography variant="body1" gutterBottom>
            {file.name}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Submit File'}
        </Button>
      </Box>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={onExportClick}>
          Export to CSV
        </Button>
      </Box>
      <div className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={subdomains.filter(row =>
            columnDefs.some(
              column =>
                row[column.field] &&
                row[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
          )}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          onColumnMoved={onColumnMoved}
          onGridReady={onGridReady}
          animateRows={true}
        />
      </div>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default SubdomainForm;
