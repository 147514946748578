import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, TextField, Container, Typography } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './custom-ag-theme-dark.css';
import axios from '../axiosConfig';
import { useThemeContext } from '../ThemeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PermuteForm = ({ selectedFolder }) => {
  const { mode } = useThemeContext();
  const [loading, setLoading] = useState(false);
  const [permutedSubdomains, setPermutedSubdomains] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    if (selectedFolder) {
      const fetchPermutedSubdomains = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${selectedFolder}/subdomains/permuted.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
          setPermutedSubdomains(data);
        } catch (error) {
          console.error(error);
          setPermutedSubdomains([]);
        }
      };
      fetchPermutedSubdomains();
    }
  }, [selectedFolder]);

  const columnDefs = [
    { headerName: 'Subdomain', field: 'subdomain', flex: 1 },
  ];

  const defaultColDef = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    flex: 1
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  useEffect(() => {
    const savedColumnState = JSON.parse(localStorage.getItem('permuteColumnsState'));
    if (savedColumnState && gridRef.current) {
      gridRef.current.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  }, []);

  const onColumnMoved = useCallback(() => {
    const columnState = gridRef.current.columnApi.getAllColumns().map(col => ({
      colId: col.colId,
      hide: col.visible === false
    }));
    localStorage.setItem('permuteColumnsState', JSON.stringify(columnState));
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    const savedColumnState = JSON.parse(localStorage.getItem('permuteColumnsState'));
    if (savedColumnState) {
      params.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    toast.info('Permutation started');

    try {
      const formData = new FormData();
      formData.append('folder', selectedFolder);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subdomains/permute`, formData);
      const data = response.data.permute.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
      setPermutedSubdomains(data);
      toast.success('Permutation completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const filteredRows = permutedSubdomains.filter((row) =>
    row.subdomain && row.subdomain.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Permuted Subdomains
      </Typography>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Folder"
          value={selectedFolder}
          fullWidth
          disabled
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? 'Loading...' : 'Start Permutation'}
        </Button>
      </Box>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={onExportClick}>
          Export to CSV
        </Button>
      </Box>
      <div className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={filteredRows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          onColumnMoved={onColumnMoved}
          onGridReady={onGridReady}
          animateRows={true}
        />
      </div>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default PermuteForm;
