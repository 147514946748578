import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Toolbar, Button } from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Dns as DnsIcon,
  Wifi as WifiIcon,
  PermDeviceInformation as PermDeviceInformationIcon,
  AccountTree as AccountTreeIcon,
  Lan as LanIcon,
  Http as HttpIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const SideMenu = ({ onLogout }) => {
  const [openSubdomains, setOpenSubdomains] = useState(false);
  const [openEndpoints, setOpenEndpoints] = useState(false);

  const handleClickSubdomains = () => {
    setOpenSubdomains(!openSubdomains);
  };

  const handleClickEndpoints = () => {
    setOpenEndpoints(!openEndpoints);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        marginTop: '64px'
      }}
    >
      <Toolbar />
      <List>
        <ListItem button component={Link} to="/all-files">
          <ListItemIcon>
            <HttpIcon />
          </ListItemIcon>
          <ListItemText primary="All Files" />
        </ListItem>
        <ListItem button onClick={handleClickSubdomains}>
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary="Subdomains" />
          {openSubdomains ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubdomains} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/enumeration">
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Enumeration" />
            </ListItem>
            <ListItem button component={Link} to="/resolve">
              <ListItemIcon>
                <WifiIcon />
              </ListItemIcon>
              <ListItemText primary="Resolution" />
            </ListItem>
            <ListItem button component={Link} to="/permute">
              <ListItemIcon>
                <PermDeviceInformationIcon />
              </ListItemIcon>
              <ListItemText primary="Permutation" />
            </ListItem>
            <ListItem button component={Link} to="/portscan">
              <ListItemIcon>
                <LanIcon />
              </ListItemIcon>
              <ListItemText primary="Port Scanning" />
            </ListItem>
            <ListItem button component={Link} to="/probe">
              <ListItemIcon>
                <HttpIcon />
              </ListItemIcon>
              <ListItemText primary="Probing" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleClickEndpoints}>
          <ListItemIcon>
            <HttpIcon />
          </ListItemIcon>
          <ListItemText primary="Endpoints" />
          {openEndpoints ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openEndpoints} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/endpoints/enumerate">
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Enumeration" />
            </ListItem>
            <ListItem button component={Link} to="/endpoints/crawl">
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Crawling" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default SideMenu;
