import React, { useState, useEffect } from 'react';
import { Box, Button, Container, TextField, Typography, Modal, MenuItem } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../axiosConfig';

const FolderSelector = ({ folders, selectedFolder, setSelectedFolder, setFolders }) => {
  const [newFolder, setNewFolder] = useState('');
  const [open, setOpen] = useState(false);
  const [toastId, setToastId] = useState(null);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/folders`);
        setFolders(response.data.folders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFolders();
  }, [setFolders]);

  const handleCreateFolder = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/folders/create`, { folder_name: newFolder });
      setFolders([...folders, newFolder]);
      setNewFolder('');
      setOpen(false);
      const id = toast.success(response.data.message, { autoClose: false, onClose: handleCloseToast });
      setToastId(id);
    } catch (error) {
      toast.error(error.response.data.error || 'Error creating folder', { autoClose: false });
    }
  };

  const handleDeleteFolder = async () => {
    const confirmed = window.confirm(`Are you sure you want to delete the folder ${selectedFolder}?`);
    if (!confirmed) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/folders/delete`, { folder_name: selectedFolder });
      setFolders(folders.filter(folder => folder !== selectedFolder));
      setSelectedFolder('');
      const id = toast.success(response.data.message, { autoClose: false, onClose: handleCloseToast });
      setToastId(id);
    } catch (error) {
      toast.error(error.response.data.error || 'Error deleting folder', { autoClose: false });
    }
  };

  const handleCloseToast = () => {
    setToastId(null);
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom align="center">
        Select Folder
      </Typography>
      <Box display="flex" justifyContent="center" mb={2}>
        <TextField
          select
          label="Folder"
          value={selectedFolder}
          onChange={(e) => setSelectedFolder(e.target.value)}
          variant="outlined"
          sx={{ width: '300px', marginRight: '16px' }}
        >
          {folders.map((folder) => (
            <MenuItem key={folder} value={folder}>
              {folder}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)} sx={{ marginRight: '8px' }}>
          Create Folder
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDeleteFolder} disabled={!selectedFolder}>
          Delete Folder
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', mt: '20%', maxWidth: 400 }}>
          <Typography variant="h6" gutterBottom align="center">
            Create New Folder
          </Typography>
          <TextField
            label="Folder Name"
            value={newFolder}
            onChange={(e) => setNewFolder(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCreateFolder}
          >
            Create
          </Button>
        </Box>
      </Modal>
      <ToastContainer position="bottom-right" onClick={handleCloseToast} />
    </Container>
  );
};

export default FolderSelector;
