import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, Container, Typography } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './custom-ag-theme-dark.css';
import axios from '../axiosConfig';
import { useThemeContext } from '../ThemeContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CrawlEndpoints = ({ selectedFolder }) => {
  const { mode } = useThemeContext();
  const [crawledEndpoints, setCrawledEndpoints] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const gridRef = useRef();

  useEffect(() => {
    if (selectedFolder) {
      const fetchCrawledEndpoints = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${selectedFolder}/endpoints/crawled_urls.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, endpoint: line }));
          setCrawledEndpoints(data);
        } catch (error) {
          console.error(error);
          setCrawledEndpoints([]);
        }
      };
      fetchCrawledEndpoints();
    }
  }, [selectedFolder]);

  const columnDefs = [
    { headerName: 'Endpoint', field: 'endpoint', flex: 1 },
  ];

  const defaultColDef = {
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    flex: 1
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  useEffect(() => {
    const savedColumnState = JSON.parse(localStorage.getItem('crawlEndpointsColumnState'));
    if (savedColumnState && gridRef.current) {
      gridRef.current.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  }, []);

  const onColumnMoved = useCallback(() => {
    const columnState = gridRef.current.columnApi.getAllColumns().map(col => ({
      colId: col.colId,
      hide: col.visible === false
    }));
    localStorage.setItem('crawlEndpointsColumnState', JSON.stringify(columnState));
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    const savedColumnState = JSON.parse(localStorage.getItem('crawlEndpointsColumnState'));
    if (savedColumnState) {
      params.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  };

  const handleSubmitCrawl = async (e) => {
    e.preventDefault();
    toast.info('Crawling started');
    const formData = new FormData();
    formData.append('folder', selectedFolder);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/endpoints/crawl`, formData);
      setCrawledEndpoints(response.data.crawled_endpoints);
      toast.success(response.data.message, { autoClose: false });
    } catch (error) {
      toast.error(error.response.data.error || 'Error');
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Crawl Endpoints
      </Typography>
      <Box component="form" onSubmit={handleSubmitCrawl} sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Start Crawling
        </Button>
      </Box>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={onExportClick}>
          Export to CSV
        </Button>
      </Box>
      <div className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={crawledEndpoints.filter(row =>
            columnDefs.some(
              column =>
                row[column.field] &&
                row[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
          )}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          onColumnMoved={onColumnMoved}
          onGridReady={onGridReady}
          animateRows={true}
        />
      </div>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default CrawlEndpoints;
