import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CssBaseline, Toolbar, Container, Button } from '@mui/material';
import axios from './axiosConfig';
import SubdomainForm from './components/SubdomainForm';
import ResolveForm from './components/ResolveForm';
import PermuteForm from './components/PermuteForm';
import ProbeResults from './components/ProbeResults';
import PortScanForm from './components/PortScanForm';
import SideMenu from './components/SideMenu';
import FolderSelector from './components/FolderSelector';
import ThemeToggleButton from './ThemeToggleButton';
import { CustomThemeProvider } from './ThemeContext';
import EnumerateEndpoints from './components/EnumerateEndpoints';
import CrawlEndpoints from './components/CrawlEndpoints';
import AllFiles from './components/AllFiles';
import Login from './Login';

function App() {
  const [selectedFolder, setSelectedFolder] = useState('');
  const [folders, setFolders] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  useEffect(() => {
    const fetchFolders = async () => {
      if (isAuthenticated) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/folders`);
          setFolders(response.data.folders);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchFolders();
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <CustomThemeProvider>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          {isAuthenticated && <SideMenu onLogout={handleLogout} />}
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <Toolbar />
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <ThemeToggleButton />
              {isAuthenticated && <Button onClick={handleLogout}>Logout</Button>}
            </Box>
            <Container maxWidth="xl">
              {isAuthenticated && (
                <FolderSelector
                  folders={folders}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  setFolders={setFolders}
                />
              )}
              <Routes>
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                <Route path="/all-files" element={isAuthenticated ? <AllFiles /> : <Navigate to="/login" />} />
                <Route path="/enumeration" element={isAuthenticated ? <SubdomainForm selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/resolve" element={isAuthenticated ? <ResolveForm selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/permute" element={isAuthenticated ? <PermuteForm selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/probe" element={isAuthenticated ? <ProbeResults selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/portscan" element={isAuthenticated ? <PortScanForm selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/endpoints/enumerate" element={isAuthenticated ? <EnumerateEndpoints selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/endpoints/crawl" element={isAuthenticated ? <CrawlEndpoints selectedFolder={selectedFolder} /> : <Navigate to="/login" />} />
                <Route path="/" element={<Navigate to={isAuthenticated ? "/" : "/login"} />} />
              </Routes>
            </Container>
          </Box>
        </Box>
      </Router>
    </CustomThemeProvider>
  );
}

export default App;
