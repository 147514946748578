import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Container, Typography, MenuItem, Select, FormControl, InputLabel, TextField } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './custom-ag-theme-dark.css';
import axios from '../axiosConfig';
import { useThemeContext } from '../ThemeContext';

const AllFiles = () => {
  const { mode } = useThemeContext();
  const [fileType, setFileType] = useState('subdomains.txt');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const gridRef = useRef();

  const initialHttpxColumns = [
    { headerName: 'Port', field: 'port', flex: 1 },
    { headerName: 'URL', field: 'url', flex: 2 },
    { headerName: 'Title', field: 'title', flex: 2 },
    { headerName: 'Webserver', field: 'webserver', flex: 2 },
    { headerName: 'Content Type', field: 'content_type', flex: 2 },
    { headerName: 'Status Code', field: 'status_code', flex: 1 },
    { headerName: 'Content Length', field: 'content_length', flex: 1 },
    { headerName: 'Tech', field: 'tech', flex: 2 },
  ];

  const handleFileTypeChange = async (event) => {
    const newFileType = event.target.value;
    setFileType(newFileType);
    await fetchData(newFileType);
  };

  const fetchData = async (type) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files/list?file_type=${type}`);
      const data = response.data.data;
      formatData(type, data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const formatData = (type, data) => {
    let formattedRows = [];
    let columnsConfig = [];

    if (type === 'subdomains.txt') {
      formattedRows = data.map((line, index) => ({ id: index, subdomain: line.trim() }));
      columnsConfig = [{ headerName: 'Subdomain', field: 'subdomain', flex: 1 }];
    } else if (type === 'urls.txt') {
      formattedRows = data.map((line, index) => ({ id: index, url: line.trim() }));
      columnsConfig = [{ headerName: 'URL', field: 'url', flex: 1 }];
    } else if (type === 'httpx_final.json') {
      formattedRows = data.map((item, index) => ({ id: index, ...item }));
      columnsConfig = initialHttpxColumns;
    }

    setRows(formattedRows);
    setColumns(columnsConfig);
  };

  useEffect(() => {
    fetchData(fileType);
  }, [fileType]);

  const handleColumnVisibilityChange = useCallback(
    (newModel) => {
      localStorage.setItem('allFilesColumnVisibilityModel', JSON.stringify(newModel));
    },
    []
  );

  useEffect(() => {
    const savedColumnVisibilityModel = JSON.parse(localStorage.getItem('allFilesColumnVisibilityModel'));
    if (savedColumnVisibilityModel) {
      gridRef.current.columnApi.applyColumnState({ state: savedColumnVisibilityModel, applyOrder: true });
    }
  }, []);

  const onColumnMoved = useCallback(() => {
    const columnState = gridRef.current.columnApi.getAllColumns().map(col => ({
      colId: col.colId,
      hide: col.visible === false
    }));
    localStorage.setItem('allFilesColumnState', JSON.stringify(columnState));
  }, []);

  const onGridReady = (params) => {
    gridRef.current = params.api;
    const savedColumnState = JSON.parse(localStorage.getItem('allFilesColumnState'));
    if (savedColumnState) {
      params.columnApi.applyColumnState({ state: savedColumnState, applyOrder: true });
    }
  };

  const filteredRows = rows.filter((row) =>
    columns.some(
      (column) =>
        row[column.field] &&
        row[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        All Files
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>File Type</InputLabel>
        <Select value={fileType} onChange={handleFileTypeChange}>
          <MenuItem value="subdomains.txt">subdomains.txt</MenuItem>
          <MenuItem value="urls.txt">urls.txt</MenuItem>
          <MenuItem value="httpx_final.json">httpx_final.json</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className={`ag-theme-alpine${mode === 'dark' ? '-dark' : ''}`} style={{ height: 800, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={filteredRows}
          columnDefs={columns}
          defaultColDef={{
            filter: true,
            floatingFilter: true,
            resizable: true,
            sortable: true,
            flex: 1
          }}
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          onColumnMoved={onColumnMoved}
          onGridReady={onGridReady}
          onColumnVisible={handleColumnVisibilityChange}
          animateRows={true}
        />
      </div>
    </Container>
  );
};

export default AllFiles;
