import React, { useState } from 'react';
import axios from '../axiosConfig';
import { TextField, Button, Box } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

const ProbeForm = ({ setProbedSubdomains, selectedFolder }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    toast.info('Probing started');

    try {
      const formData = new FormData();
      formData.append('folder', selectedFolder);

      const token = Cookies.get('token'); // Obtém o token dos cookies

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subdomains/probe`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Adiciona o cabeçalho de autorização
        },
      });
      setProbedSubdomains(response.data.probe);
      toast.success('Probing completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <TextField
        label="Folder"
        value={selectedFolder}
        fullWidth
        disabled
        required
        sx={{ mb: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        {loading ? 'Loading...' : 'Start Probing'}
      </Button>
      <ToastContainer position="bottom-right" />
    </Box>
  );
};

export default ProbeForm;
